import React from "react";
import "./notFound.css";
import { Link } from "react-router-dom";
import notFoundImg from "../../../assets/img/notFoundImg.png"

const NotFound = () => {
  return (
    <section className="notFound">
      <div className="container-fluid">
        
          <div className="box">
            <img src={notFoundImg} alt="Not Found" />
            <h3><Link to='/'>Back to home</Link></h3>
          </div>
      
      </div>
    </section>
  );
};

export default NotFound;
