import React, { useState } from "react";
import "./quantityBox.css";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const QuantityBox = () => {

    const [inputValue, setInputValue] = useState(1);

    const plus = () => {
      setInputValue(inputValue + 1);
    };
  
    const minus = () => {
      if (inputValue != 1) {
        setInputValue(inputValue - 1);
      }
    };


  return (
    <div className="counterSec mr-2">
      <input type="number" value={inputValue} />
      <span className="arrow plus">
        <KeyboardArrowUpOutlinedIcon onClick={plus} />
      </span>
      <span className="arrow minus">
        <KeyboardArrowDownOutlinedIcon onClick={minus} />
      </span>
    </div>
  );
};

export default QuantityBox;
