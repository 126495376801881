import React from "react";
import "./about.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import Banner12 from "../../../assets/img/banner-12.jpg";
import Banner13 from "../../../assets/img/banner-13.png";
import Icon1 from "../../../assets/img/icon-1.svg";
import Icon2 from "../../../assets/img/icon-2.svg";
import Icon3 from "../../../assets/img/icon-3.svg";
import Icon4 from "../../../assets/img/icon-4.svg";
import Icon5 from "../../../assets/img/icon-5.svg";
import Icon6 from "../../../assets/img/icon-6.svg";

const About = () => {
  return (
    <section className="listingPage">
      <div className="container-fluid">
        <div className="breadcrumb1 flex-column">
          <h3>About</h3>
          <ul className="list-inline-item mb-0">
            <li className="list-inline-item">
              <Link to="/">Home </Link>
            </li>
            <li className="list-inline-item">
              <ArrowForwardIcon /> About
            </li>
          </ul>
        </div>

        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto about">
            <section className="row  mb-50 ">
              <div className="col-lg-6 bimgdiv">
                <img src={Banner12} alt="About" className="w-100 bimg" />
              </div>
              <div className="col-lg-6">
                <h2>Welcome to Vital Seeds</h2>

                <p>
                  Vital Seeds India Pvt. Ltd. specializes in revitalizing your
                  gardens with premium seeds that ensure robust, healthy, and
                  plentiful development. With a strong dedication to innovation
                  and sustainability, we work to provide every Gardner
                  enthusiast and farmer with the best seeds possible for a
                  prosperous future. Vital is well known for its superior R&D
                  capabilities. Vital Seeds is a low-investment product that is
                  all natural, safe, and yields high returns. In addition,
                  essential seeds are quite affordable when compared to other
                  firms' seeds. An essential agricultural resource has always
                  been seed. Farmers have been receiving high-quality harvests
                  for a very long time, which allows them to make enormous
                  profits and enjoy themselves.
                </p>
                <p>
                  All seeds that are essential to the preservation of
                  agricultural biodiversity and the long-term production of food
                  crops are considered vital seeds. These seeds come in a
                  variety of forms that have been historically and culturally
                  significant, have distinct genetic qualities, and have adapted
                  to a variety of environmental circumstances. They consist of
                  both contemporary varieties and conventional landraces that
                  have been created via breeding initiatives to enhance
                  agricultural yields, insect resistance, and nutritional value.
                </p>
                <p>
                  Sustainable agriculture and the value of biodiversity in
                  guaranteeing food security and environmental resilience have
                  received increased attention in recent years. Crucial seeds
                  are at the center of these initiatives. Crucial seeds are
                  necessary not just for crop production but also for preserving
                  genetic variety, resilience to climate change, and
                  long-standing agricultural traditions. This article examines
                  the importance of essential seeds, their advantages, the
                  difficulties in preserving them, and the prospects for
                  sustainable agriculture in the future.
                </p>
              </div>
            </section>
            <section className="text-center">
              <h2 className="title style-3 ">Why Choose Vital Seeds?</h2>
              <div className="row featured">
                <div className="col-lg-4 col-md-6 mb-24">
                  <div className="featured-card">
                    <img src={Icon2} alt="" />
                    <h4>Quality Assurance</h4>
                    <p>
                      Each seeds is selected and tested to ensure superior
                      germination rates and genetic. It ensures that the meet
                      .....
                    </p>
                    <a href="#">Read more</a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-24">
                  <div className="featured-card">
                    <img src={Icon3} alt="" />
                    <h4>Expertise</h4>
                    <p>
                      Backed by years of experience and knowledge, our team is
                      dedicated to providing expert advice and support.....
                    </p>
                    <a href="#">Read more</a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-24">
                  <div className="featured-card">
                    <img src={Icon4} alt="" />
                    <h4>Sustainability</h4>
                    <p>
                      We categorize balanced at every stage, from seed
                      production to packaging, minimizing our environmental
                      .....
                    </p>
                    <a href="#">Read more</a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-24">
                  <div className="featured-card">
                    <img src={Icon5} alt="" />
                    <h4>Customer Satisfaction </h4>
                    <p>
                      Your success is our success. We are committed to building
                      lasting relationships with our customers by delivering
                      exceptional products.....
                    </p>
                    <a href="#">Read more</a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-24">
                  <div className="featured-card">
                    <img src={Icon1} alt="" />
                    <h4>Best Prices & Offers</h4>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form
                    </p>
                    <a href="#">Read more</a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-24">
                  <div className="featured-card">
                    <img src={Icon6} alt="" />
                    <h4>Great Daily Deal</h4>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form
                    </p>
                    <a href="#">Read more</a>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="row align-items-center"
              style={{ marginTop: 50 }}
            >
              <div className="row mb-50 align-items-center">
                <div className="col-lg-7 pr-30">
                  <img src={Banner13} alt="" className="w-100 bimg2" />
                </div>
                <div className="col-lg-5">
                  <h3 className="title style-4">Who We Are</h3>
                  <p>
                    We at Vital Seeds are ardent supporters of biodiversity and
                    sustainable agriculture. Our company, which was established
                    on the values of ecological stewardship and community
                    resilience, specializes in offering premium organic seeds
                    that enable people and communities to cultivate their own
                    wholesome food while protecting biodiversity.
                  </p>
                  <p>
                    Beyond only selling seeds, we also support regenerative
                    farming methods that improve soil health and lessen their
                    negative effects on the environment. By collaborating with
                    small-scale farmers and doing educational outreach, our goal
                    is to promote a closer relationship between consumers and
                    the food they eat.
                  </p>
                  <p>
                    Our attitude is centered on honesty and openness. To make
                    sure our seeds live up to the high performance requirements
                    and strict organic standards, we carefully choose and test
                    them. Our team consists of committed professionals with
                    horticultural, sustainability, and customer service skills
                    who are united by a common goal of advancing responsible
                    agriculture and a healthier world.
                  </p>
                </div>
              </div>
              <div className="row whoweare2" style={{ marginTop: 30 }}>
                <div className="col-lg-4 pr-30 mb-md-5 mb-lg-0 mb-sm-5">
                  <h3 className="mb-30">Our Mission</h3>
                  <p>
                    At Vital seeds, our journey is to stimulate sustainable
                    Agricultural and Gardening and gardening practice by
                    presuming superior quality seeds that ensure copious harvest
                    and healthier environment....
                    <a href="#">Read more</a>
                  </p>
                </div>
                <div className="col-lg-4 pr-30 mb-md-5 mb-lg-0 mb-sm-5">
                  <h3 className="mb-30">Our Vision</h3>
                  <p>
                    At Vital Seeds, we see a prosperous future for sustainable
                    agriculture that supports both ecosystems and communities.
                    Our mission is to enable people and organizations to develop
                    robust, wholesome food systems....
                    <a href="#">Read more</a>
                  </p>
                </div>
                <div className="col-lg-4">
                  <h3 className="mb-30">Our Goal</h3>
                  <p>
                    Our goal at Vital Seeds is to transform agriculture by
                    utilizing community-driven projects and sustainable methods.
                    Our commitment is to supply premium, varied seeds that
                    support resilience and biodiversity....
                    <a href="#">Read more</a>
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
