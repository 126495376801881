import React, { useState } from "react";
import "./contact.css";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const defaultContactFormData = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

const Contact = () => {
  const [contact, setContact] = useState({ defaultContactFormData });

  // handling the input value
  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setContact({
      ...contact,
      [name]: value,
    });
  };

  // handling contact form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/form/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contact),
      });

      if (response.ok) {
        setContact(defaultContactFormData);
        const responseData = await response.json();
        toast.success("Message Sent Successfully");
        console.log(responseData);
      } else {
        // Handle API error here
        console.error("API Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <section className="listingPage">
      {/* breadcrumb start here */}
      <div className="container-fluid">
        <div className="breadcrumb1 flex-column">
          <h3>Contact</h3>
          <ul className="list-inline-item mb-0">
            <li className="list-inline-item">
              <Link to="/">Home </Link>
            </li>
            <li className="list-inline-item">
              <ArrowForwardIcon /> Contact
            </li>
          </ul>
        </div>
      </div>
      {/* breadcrumb end here */}

      {/* contact section start here */}
      <div className="contact">
        <div className="container-fluid">
          {/* map start here */}
          <div className="map-sec">
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3597.763080048457!2d85.12755397474109!3d25.612787814743672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed5975c433da41%3A0x75a975b6aa6164!2sUPL%20InfoTech!5e0!3m2!1sen!2sin!4v1721982739058!5m2!1sen!2sin"
                title="Map"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
          {/* map end here */}

          {/* contact main start here */}
          <div className="row contact-main">
            {/* contact information start here */}
            <div className="col-xl-5 mb-xl-0 ">
              <div className="me-xxl-5 mt-60">
                <div className="title-area mb-25">
                  <h2 className="border-title h3">Have Any Questions?</h2>
                </div>
                <p className="contact-para">
                  If you have any specific questions or concerns, please don't
                  hesitate to ask. We're here to help!
                </p>
                <div className="contact-feature">
                  <div className="contact-feature-icon">
                    <LocationOnIcon />
                  </div>
                  <div className="media-body">
                    <p className="contact-feature_label">Regd. Office</p>
                    <NavLink to="#" className="contact-feature_link">
                      502, Balaji Dream Homes, Aparna Bank Colony, Patna, 801503
                    </NavLink>
                  </div>
                </div>
                <div className="contact-feature">
                  <div className="contact-feature-icon">
                    <LocationOnIcon />
                  </div>
                  <div className="media-body">
                    <p className="contact-feature_label">Facilitation Office</p>
                    <NavLink to="#" className="contact-feature_link">
                      204, Atul Vilas Kunj Apartment, Kidwaipuri, Patna, 800001
                    </NavLink>
                  </div>
                </div>
                <div className="contact-feature">
                  <div className="contact-feature-icon">
                    <LocalPhoneIcon />
                  </div>
                  <div className="media-body">
                    <p className="contact-feature_label">Phone</p>
                    <NavLink
                      to="tel:+917370050737 "
                      className="contact-feature_link"
                    >
                      Mobile : <span>+91-7370050737 </span>
                    </NavLink>
                    <NavLink
                      to="tel:+917079019555 "
                      className="contact-feature_link"
                    >
                      Mobile : <span>+91-7079019555 </span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            {/* contact information end here */}

            {/* contact form start here */}
            <div className="col-xl-6">
              <div className="contact-form-wrap">
                <span className="sub-title">Contact With Us!</span>
                <h2 className="border-title">Get in Touch</h2>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="off"
                          value={contact.name}
                          onChange={handleInput}
                          required
                          className="form-control style-white"
                          placeholder="Your Name*"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          autoComplete="off"
                          value={contact.email}
                          onChange={handleInput}
                          required
                          className="form-control style-white"
                          placeholder="Your Email Address*"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control style-white"
                          name="phone"
                          id="number"
                          autoComplete="off"
                          value={contact.phone}
                          onChange={handleInput}
                          required
                          placeholder="Your Phone Number*"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          id="message"
                          autoComplete="off"
                          value={contact.message}
                          onChange={handleInput}
                          required
                          cols={30}
                          rows={6}
                          className="form-control style-white"
                          placeholder="Write Your Message*"
                        />
                      </div>
                    </div>
                    <div className="form-btn col-12 mt-10">
                      <button className="th-btn" type="submit">
                        Send Message &nbsp;
                        <ArrowForwardIcon />
                      </button>
                    </div>
                  </div>
                  <p className="form-messages mb-0 mt-3" />
                </form>
              </div>
            </div>
            {/* contact form end here */}
          </div>
          {/* contact main end here */}
        </div>
      </div>
      {/* contact section end here */}
    </section>
  );
};

export default Contact;
