import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import './users.css'; // Import the external CSS file
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords didn't match");
      setPassword("");
      setConfirmPassword("");
      return;
    }

    setError("");
    const uu_id = window.location.pathname.split("/").pop();
    const requestData = { uu_id, password };

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/password/resetpassword`, requestData);
      toast.success(res.data.message);
      navigate('/login')
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  return (
    <div className="container">
      <h1 className="h1">Reset Password Here</h1>
      <form onSubmit={handleSubmit} className="form">
        <input
          type="Password"
          placeholder="Enter Your New Password"
          id="psw"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input"
        />
        <input
          type="password"
          placeholder="Enter Your New Confirm Password"
          id="cnf-psw"
          required
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="input"
        />
        <button type="submit" id="btn" className="button">
          Change Password
        </button>
      </form>
      {error && <p className="errorMessage">{error}</p>}
    </div>
  );
};

export default ResetPassword;
