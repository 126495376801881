import React from "react";
import "./style.css";
import Slider from "react-slick";
import Slide from "./slider/Slide";
import Cat_Slider from "../../catSlider/Cat_Slider";
import Banners from "../../banners/Banners";
import Products from "../../product/Products";
import Banner4 from "../../../assets/img/banner-4.png";
import TopProducts from "../../topProducts/TopProducts";
import { FaLongArrowAltRight } from "react-icons/fa";
import {NavLink }from 'react-router-dom';


const index = () => {
  var settings = {
    dots: false, //dots ko slider se remove krne ke liye
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    fade: false,
    autoplay: 100,
    arrows: true, //arrow ko slider me add krne ke liye over image
    responsive: [
      {
        breakpoint: 1024, // screen width less than 1024px
        settings: {
          slidesToShow: 3, // show 3 slides
        }
      },
      {
        breakpoint: 768, // screen width less than 768px
        settings: {
          slidesToShow: 2, // show 2 slides
        }
      },
      {
        breakpoint: 480, // screen width less than 480px
        settings: {
          slidesToShow: 2, // show 1 slide
        }
      }
    ]
  };

  return (
    <>
    <Slide />
    <Cat_Slider />
    <Banners /> 

    <section className="homeProducts">
      <div className="container-fluid">
        <div className="d-flex align-items-center">
          <h2 className="hd mb-0 mt-0" style={{color:'black'}}>Popular Products</h2>
          <ul className="list list-inline ml-auto filterTab mb-0">
            <li className="list-inline-item">
              <a className="cursor">All</a>
            </li>
            <li className="list-inline-item">
              <a className="cursor">Grain Seeds</a>
            </li>
            <li className="list-inline-item">
              <a className="cursor">Vegetables Seeds</a>
            </li>
          </ul>
        </div>

        <div className="productRow">
          <div className="item">
            <Products />
          </div>
          <div className="item">
            <Products />
          </div>
          <div className="item">
            <Products />
          </div>
          <div className="item">
            <Products />
          </div>
          <div className="item">
            <Products />
          </div>
          <div className="item">
            <Products />
          </div>
          <div className="item">
            <Products />
          </div>
          <div className="item">
            <Products />
          </div>
          <div className="item">
            <Products />
          </div>
          <div className="item">
            <Products />
          </div>
        </div>
      </div>
    </section>

    <section className="homeProducts homeProductsRow2 pt-0">
      <div className="container-fluid">
        <div className="d-flex align-items-center">
          <h2 className="hd mb-0 mt-0" style={{color:'black'}}>Daily Best Sells</h2>
          <ul className="list list-inline ml-auto filterTab mb-0">
            <li className="list-inline-item">
              <a className="cursor">Featured</a>
            </li>
            <li className="list-inline-item">
              <a className="cursor">Popular</a>
            </li>
            <li className="list-inline-item">
              <a className="cursor">New Added</a>
            </li>
          </ul>
        </div>
        <br />
        <div className="row">
          <div className="col-md-3 banner-img1 ">
            <img src={Banner4} className="w-100 banner4" />
            <div className="banner-text">
              <h4>
              Plant the <br /> Difference &amp; Taste <br />the Freshness
              </h4>
              <NavLink to={'/shop'} className="btn-bnr">
                Shop Now &nbsp; <FaLongArrowAltRight />
              </NavLink>
            </div>
          </div>
          
          <div className="col-md-9">
            <Slider {...settings} className="proSlider">
              <Products />
              <Products />
              <Products />
              <Products />
            </Slider>
          </div>
        </div>
      </div>
    </section>

    <section className="topProductsSection">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <TopProducts title="Top Selling" />
          </div>
          <div className="col" >
            <TopProducts title="Trending Products"/>
          </div>
          <div className="col" >
            <TopProducts title="Recently added" />
          </div>
          <div className="col" >
            <TopProducts title="Top Rated" />
          </div>
        </div>
      </div>
    </section>
  </>
  );
};

export default index;
