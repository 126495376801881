import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import Logo from "../../assets/img/vsheaderlogo.png";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Nav from "../nav/Nav";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector} from 'react-redux';

const Header = () => {
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  
  const loggedIn = useSelector((state) => state.loggedIn);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const headerRef = useRef();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let position = window.pageYOffset;
      if (position > 100) {
        headerRef.current.classList.add("fixed");
      } else {
        headerRef.current.classList.remove("fixed");
      }
    });
  }, []);

  const signOutHandler = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/logout`, {
        headers: { Authorization: `Bearer ${token}` },
      }); 
      console.log(res.data);
      toast.success(res.data.message);
      sessionStorage.removeItem('token');
      navigate('/login');
      window.location.reload();
    } catch (error) {
      toast.error('Sign out failed. Please try again.');
      console.error(error);
    }
  };

  return (
    <>
      <div className="headerWrapper" ref={headerRef}>
        <header>
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-sm-2 col-6">
                <img src={Logo} alt="Logo" />
              </div>

              <div className="col-sm-5 d-none d-sm-block">
                <div className="headerSearch d-flex align-items-center">
                  <div className="selectDrop cursor position-relative">
                    All Categories
                  </div>
                  <div className="search">
                    <input type="text" placeholder="Search for Items.." />
                    <SearchIcon className="searchIcon cursor" />
                  </div>
                </div>
              </div>

              <div className="col-sm-5 col-6 d-flex align-items-center justify-content-end">
                <div className="ml-auto d-flex align-items-center">
                 
                    <ul className="headerTabs ">
                      <li className="list-inline-item">
                        <span>
                          
                          <span className="badge bg-success rounded-circle">3</span>
                          <Link className="addCart" to={'/products/AddToCart'}> <AddShoppingCartIcon className="icon" /></Link>
                        </span>
                      </li>
                      
                      <li className="list-inline-item">
                        <span
                          onClick={() => loggedIn ? setIsOpenDropDown(!isOpenDropDown) : navigate('/login')}
                        >
                          <PermIdentityIcon className="icon" />
                          {loggedIn ? user.user : ''}
                        </span>
                        {isOpenDropDown && (
                          <ul className="dropDownMenu">
                            <li>
                              <Button>
                                <PermIdentityIcon />
                                &nbsp;My Account
                              </Button>
                            </li>
                            <li>
                              <Button>
                                <FavoriteBorderIcon />
                                &nbsp;My Wishlist
                              </Button>
                            </li>
                            <li>
                              <Button>
                                <AddShoppingCartIcon />
                                &nbsp;My Orders
                              </Button>
                            </li>
                            <li>
                              <Button onClick={signOutHandler}>
                                <PermIdentityIcon />
                                &nbsp;Sign Out
                              </Button>
                            </li>
                          </ul>
                        )}
                      </li>
                    </ul>
                    

                  {/* Toggle button for mobile view */}
                  <div className="d-block d-sm-none">
                    <MenuIcon className="menu-icon cursor" onClick={() => setIsNavOpen(!isNavOpen)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </header>

        {/* Always render Nav on desktop */}
        <div className="d-none d-sm-block">
        <Nav/>
        </div>

        {/* Render Nav based on isNavOpen state on mobile */}
        {isNavOpen && (
          <div className="d-block d-sm-none">
            <Nav isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
