import React, { useState } from "react";
import "./cart.css";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import product from "../../../assets/img/seeds-1.webp";
import Rating from "@mui/material/Rating";

import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import QuantityBox from "../../quantityBox/QuantityBox";

const Cart = () => {


  return (
    <>
      <div className="container-fluid">
        <div className="breadcrumb1 flex-column">
          <h3>Shop</h3>
          <ul className="list-inline-item mb-0">
            <li className="list-inline-item">
              <Link to="/">Home </Link>
            </li>
            <li className="list-inline-item">
              <ArrowForwardIcon /> <Link to={"/shop"}>Shop</Link>
            </li>
            <li className="list-inline-item">
              <ArrowForwardIcon />
              Cart
            </li>
          </ul>
        </div>
      </div>

      <section className="cartSection mb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="d-flex align-items-center w-100">
                <div className="left">
                  <h1 className="hd mb-0">Your Cart</h1>
                  <p>
                    There are <span className="text-g">3</span> Products in your
                    cart.
                  </p>
                </div>
                <span className="ml-auto d-flex align-items-center clearCart">
                  <DeleteForeverIcon /> Clear Cart{" "}
                </span>
              </div>

              <div className="cartWrapper">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>SubTotal</th>
                        <th>Remove</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={product}
                              alt="cartimage"
                              className="cartimage"
                            />

                            <div className="info pl-4">
                              <Link to={"/products/details"}>
                                <h6>Grain Seeds</h6>
                              </Link>
                              <Rating
                                name="half-rating-read"
                                defaultValue={3.5}
                                precision={0.5}
                                readOnly
                              />
                              <span>(4.5)</span>
                            </div>
                          </div>
                        </td>
                        <td>Rs.56</td>
                        <td>
                        <QuantityBox/>
                        </td>
                        <td>
                          <span className="text-g subTotal">Rs. 200</span>
                        </td>
                        <td>
                          <span>
                            {" "}
                            <DeleteIcon />{" "}
                          </span>
                        </td>
                      </tr>

                      

                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={product}
                              alt="cartimage"
                              className="cartimage"
                            />

                            <div className="info pl-4">
                              <Link to={"/products/details"}>
                                <h6>Grain Seeds</h6>
                              </Link>
                              <Rating
                                name="half-rating-read"
                                defaultValue={3.5}
                                precision={0.5}
                                readOnly
                              />
                              <span>(4.5)</span>
                            </div>
                          </div>
                        </td>
                        <td>Rs.56</td>
                        <td>
                        <QuantityBox/>
                        </td>
                        <td>
                          <span className="text-g subTotal">Rs. 200</span>
                        </td>
                        <td>
                          <span>
                            {" "}
                            <DeleteIcon />{" "}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={product}
                              alt="cartimage"
                              className="cartimage"
                            />

                            <div className="info pl-4">
                              <Link to={"/products/details"}>
                                <h6>Grain Seeds</h6>
                              </Link>
                              <Rating
                                name="half-rating-read"
                                defaultValue={3.5}
                                precision={0.5}
                                readOnly
                              />
                              <span>(4.5)</span>
                            </div>
                          </div>
                        </td>
                        <td>Rs.56</td>
                        <td>
                          <QuantityBox/>
                        </td>
                        <td>
                          <span className="text-g subTotal">Rs. 200</span>
                        </td>
                        <td>
                          <span>
                            {" "}
                            <DeleteIcon />{" "}
                          </span>
                        </td>
                      </tr>

                    </tbody>
                  </table>

                  <Button className="btn-cart">Continue Shoping</Button>

                  <Button className="btn-cart" style={{float:"right"}}>Place Order</Button>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="priceDetails">
                <h5 className="priceDetailsTitle">Price details</h5>
                <div className="priceDetailsBody">
                  <div className="priceBody">
                    <div className="priceBodyTitle">Price (1 item)</div>

                    <div>
                      <span className="priceBodyDetails">₹2,199</span>
                    </div>
                  </div>
                  <div className="priceBody">
                    <div className="priceBodyTitle">Discount</div>

                    <div>
                      <span className="priceBodyDetails">− ₹1,220</span>
                    </div>
                  </div>
                  <div className="priceBody">
                    <div className="priceBodyTitle">Delivery Charges</div>

                    <div>
                      <span className="priceBodyDetails">Free</span>
                    </div>
                  </div>
                  <div className="totalAmountBody">
                    <h6 className="totalAmountTitle">Total Amount</h6>

                    <div className="totalAmountDetails">
                      <span> ₹979</span>
                    </div>
                  </div>
                  <div className="savePrice">
                    You will save ₹1,220 on this order
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
