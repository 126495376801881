import React, { useState } from "react";
import "./catSlider.css";
import Slider from "react-slick";
import Cat from "../../../src/assets/img/cat-13.png";

const Cat_Slider = () => {
  const [itemBg, setItemBg] = useState([
    "#fffceb",
    "#fffceb",
    "#feefea",
    "#fffceb",
    "#fffceb",
    "#feefea",
    "#fffceb",
    "#fffceb",
    "#feefea",
    "#feefea",
  ]);
  var settings = {
    dots: false, //dots ko slider se remove krne ke liye
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    fade: false,
    arrows: true, //arrow ko slider me add krne ke liye over image
    autoplay:500,
    centerMode:true,
    responsive: [
      {
        breakpoint: 1024, // screen width less than 1024px
        settings: {
          slidesToShow: 6, // show 3 slides
        }
      },
      {
        breakpoint: 768, // screen width less than 768px
        settings: {
          slidesToShow: 4, // show 2 slides
        }
      },
      {
        breakpoint: 480, // screen width less than 480px
        settings: {
          slidesToShow: 3, // show 1 slide
        }
      },
      {
        breakpoint: 360, // screen width less than 480px
        settings: {
          slidesToShow: 2, // show 1 slide
        }
      }
    ]
  };
  return (
    <>
      <div className="catSliderSection">
        <div className="container-fluid">
          <h2 className="hd">Featured Categories</h2>
          <Slider {...settings} className="catSliderMain">
            {itemBg.length !== 0 &&
              itemBg.map((item, index) => {
                return (
                  <div className="item">
                    <div className="info" style={{ background: item }}>
                      <img src={Cat} className="catimg"/>
                      <h6>Black Musturd</h6>
                      <p>26 items</p>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Cat_Slider;
