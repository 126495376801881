import React from "react";
import './shop.css';
import { Link } from "react-router-dom";
import SideBar from "../../sideBar/SideBar";
import Products from "../../product/Products";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Shop = () => {
  return (
    <>
      <section className="listingPage">
        <div className="container-fluid">
          <div className="breadcrumb1 flex-column">
            <h3>Shop</h3>
            <ul className="list-inline-item mb-0">
              <li className="list-inline-item">
                <Link to="/">Home </Link>
              </li>
              <li className="list-inline-item"><ArrowForwardIcon/> Shop</li>
            </ul>
          </div>  

          <div className="listingData ">
            <div className="row">
              <div className="col-md-3 sidebarWrapper " >
                <SideBar />
              </div>
              <div className="col-md-9 rightContent homeProducts  pt-0">
                <div className="productRow pl-4 pr-3" >
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                  <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                  <div className="item2">
                    <Products />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Shop;
