import React from "react";
import "./products.css";
import Seed1 from "../../assets/img/seeds-1.webp";
import Rating from "@mui/material/Rating";
import { Button } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Tooltip from "@mui/material/Tooltip";
import { NavLink } from "react-router-dom";

const Products = () => {
  return (
    <div className="productThumb">
      <span className="badge">Sale</span>
      <NavLink to="/products/details">
        <div className="imgWrapper">
          <img src={Seed1} />

          <div className="overlay">
            <ul className="list list-inline mb-0">
              <Tooltip title="Quick View">
                <li className="list-inline-item">
                  <NavLink to="/products/details">
                    <VisibilityOutlinedIcon />
                  </NavLink>
                </li>
              </Tooltip>
              <Tooltip title="Add to Wishlist">
                <li className="list-inline-item">
                  <NavLink to="/products/details">
                    <FavoriteBorderIcon />
                  </NavLink>
                </li>
              </Tooltip>
            </ul>
          </div>
        </div>
      </NavLink>

      <div className="info">
        <span className="d-block catName">Grain Seeds</span>
        <h4 className="title">
          <NavLink to="/products/details">Black Musturd </NavLink>
        </h4>
        <Rating
          name="half-rating-read"
          defaultValue={3.5}
          precision={0.5}
          readOnly
          style={{fontSize:18}}
        />
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <span className="price font-weight-bold">Rs.28.85</span>
            <span className="oldPrice">Rs.28.85</span>
          </div>
          <Button className="bg-g ml-auto">
            <AddShoppingCartIcon />
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Products;
