import React, { useEffect, useState } from "react";
import "./blog-post-details.css";
import { Link, useParams } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const BlogPostDetails = () => {

  const { id } = useParams(); // Get the blog ID from the URL
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/blog/getBlog/${id}`);
        const data = await response.json();
        setBlog(data);
      } catch (error) {
        console.error("Error fetching blog details:", error);
      }
    };

    fetchBlogDetails();
  }, [id]);

  if (!blog) {
    return <div>Loading...</div>; // Show a loading state while fetching data
  }
  
  return (
    <div className="page-content mb-50">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9 m-auto">
            <div className="single-page pt-50 pr-30">
              <div className="single-header style-2">
                <div className="row">
                  <div className="col-xl-10 col-lg-12 m-auto">
                    <h2 className="blog-detail-heading mt-30">
                    {blog.title}
                    </h2>
                    <div className="single-header-meta">
                      <div className="d-flex list-align-item">
                        <div className="single-header-cat-left">
                          <h6>
                            <Link to="/blog-category">{blog.category}</Link>
                          </h6>
                        </div>
                        <div className="single-header-date-right">
                          <h6>{new Date(blog.createdAt).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "short", 
                              year: "numeric",
                            }
                          )}</h6>
                        </div>
                        <div className="social-icons">
                          <FacebookShareButton
                            style={{ marginRight: 8, marginLeft: 20, outline:'none'}}
                            url={`${process.env.REACT_APP_CLIENT_URL}/${blog._id}`}
                          >
                            <FacebookIcon size={20} round />
                          </FacebookShareButton>
                          <WhatsappShareButton style={{ outline:'none'}} url={`${process.env.REACT_APP_CLIENT_URL}/${blog._id}`}>
                            <WhatsappIcon size={20} round />
                          </WhatsappShareButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <figure className="single-thumbnail">
                <img src={`${process.env.REACT_APP_API_URL}/${blog.imageUrl}`}  alt="" />
              </figure>
              <div className="single-content">
                <div className="row">
                  <div className="col-xl-10 m-auto details-desc">
                    <p className="single-excerpt"
                    
                    dangerouslySetInnerHTML={{
                      __html: blog.content,
                    }}>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPostDetails;