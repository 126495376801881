import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoggedIn, setUser } from "../../store/store.js";

const Login = () => {
  const dispatch = useDispatch();

  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
 
    const user = { mail, password };

    try {
      const res = await axios.post(
       `${process.env.REACT_APP_API_URL}/api/users/login`,
        user
      );
      console.log(res.data);
      if (res.data.success) {
        dispatch(setLoggedIn(true));
        sessionStorage.setItem("token", res.data.token);

        dispatch(
          setUser({
            user: res.data.user,
            email: res.data.email,
            admin: res.data.admin,
          })
        );
        toast.success(res.data.message);

        
          navigate("/"); // Internal route within the same React app
        
      
      } else {
        toast.warning(res.data.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="container">
      <h1 className="h1">Login Here</h1>
      <form onSubmit={handleSubmit} className="form">
        <input
          type="email"
          placeholder="Email Id"
          id="mailId"
          required 
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          className="input"
        />
        <input
          type="password"
          placeholder="Password"
          id="psw"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input"
        />
        <button type="submit" id="btn" className="button">
          login
        </button>
        <p>
          If you forgot your password,{" "}
          <a href="/forget-password" className="link">
            click here
          </a>
        </p>
      </form>
      <a href="/signup" className="link">
        Don't have an account? Click here to SignUp
      </a>
    </div>
  );
};

export default Login;
