import React from "react";
import "./footer.css";
import Logo from "../../assets/img/vsfooterlogo.png";
import uplicon from "../../assets/img/uplinfotech.png";
import { Link } from "react-router-dom";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

const Footer = () => {
  return (
    <>
      <footer className="footerSection">
        <div className="container-fluid">
          <div className="row footer-main">
            <div className="col-md-4 footer-part1">
              <Link to="/">
                <img src={Logo} />
              </Link>

              <div className="footer-body mt-3 p-2 mb-3">
                <p
                  className="pdesc"
                  style={{ color: "white", textAlign: "justify" }}
                >
                  Vital Seeds India Pvt. Ltd. specializes in revitalizing your
                  gardens with premium seeds that ensure robust, healthy, and
                  plentiful development.
                </p>

                <p className="footer-contact_text">Our Location:</p>
                <div className="loc_link">
                  <a
                    href="https://maps.app.goo.gl/tbAe4CGp4juCsAq7A"
                    className="footer-contact_link text-capitalize"
                    target="_blank"
                  >
                    J47J+436, Atul Vilas Kunj Apartment, Kidwaipuri Kidwaipuri,
                    Buddha Colony, Patna, Bihar 800001
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 footer-part2">
              <h3 className="widget_title">Quick Link</h3>
              <ul className="list list-inline items-footer">
                <li>
                  <Link to="/">Home </Link>
                </li>
                <li>
                  <Link to="/shop">Products </Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4 footer-part3">
              <div className="footer-widget">
                <h3 className="widget_title">Get in touch!</h3>
                <div className="footer-contact_link">
                  <div className="media-body">
                    <p className="footer-contact_text">
                      Call us : Mon – Sat : 10:00AM - 6:00PM
                    </p>
                    <Link to="tel:+91-7370050737 "> +91-7370050737 </Link>
                    <Link to="tel:+91-7079019555  "> +91-7079019555 </Link>
                  </div>

                  <div className="mt-3 ">
                    <p className="footer-contact_text">Email us :</p>
                    <Link to="mailto:farmers@vitalseeds.in">
                      farmers@vitalseeds.in
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  copywriteWrapper*/}
          <div className="copywriteWrapper">
            <div className="row">
              <div className="col-md-6">
                <p className="copyright-text">
                  © 2024 &nbsp;
                  <Link to="/">Vital Seeds&nbsp;</Link>
                  All Rights Reserved.&nbsp; Powered By&nbsp;
                  <Link to="https://uplinfotech.com/" target="_blank">
                    <img
                      src={uplicon}
                      alt="UPL InfoTech"
                      title="UPL InfoTech"
                    />
                  </Link>
                </p>
              </div>

              <div className="col-md-6 ">
                <div className="footer-social-links">
                  <ul className="list list-inline mb-0">
                    <li className="list-inline-item">
                      <Link
                        to={`${process.env.REACT_APP_ADMIN_URL}/admin/login`}
                      >
                        <PermIdentityIcon />
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/">Privacy Policy</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/"> Terms & Condition</Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/">Refund Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
