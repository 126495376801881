// store.js
import { createStore } from 'redux';

// Initial state
const initialState = {
    user: null,
    theme: 'light', // Adding a new state for theme
    loggedIn: false
};

// Action types
const SET_USER = 'SET_USER';
const SET_THEME = 'SET_THEME'; // New action type for theme
const SET_LOGGEDIN = 'SET_LOGGEDIN'

// Action creators
export const setUser = (user) => ({
    type: SET_USER,
    payload: user,
});

export const setTheme = (theme) => ({
    type: SET_THEME,
    payload: theme,
});
export const setLoggedIn = (loggedIn) => ({
    type: SET_LOGGEDIN,
    payload: loggedIn,
});

// Reducer
const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return { ...state, user: action.payload };
        case SET_THEME: // Handling the new theme state
            return { ...state, theme: action.payload };
        case SET_LOGGEDIN:
            return { ...state, loggedIn: action.payload };
        default:
            return state;
    }
};

// Create store
const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() // For Redux DevTools
);

export default store;
