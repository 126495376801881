import React, { useState, useEffect } from "react";
import "./slider.css";
import Slider from "react-slick";
import axios from "axios";

const Slide = () => {
  var settings = {
    dots: true, //dots ko slider se remove krne ke liye
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: true, //arrow ko slider me add krne ke liye over image
  };

  const [slider, setSlider] = useState([]);

  useEffect(() => {
    fetchSliderData();
  }, []);

  // Fetch gallery data function
  const fetchSliderData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/upload`);

      if (response.status === 200) {
        setSlider(response.data);
      } else {
        console.error("Failed to fetch slider details");
      }
    } catch (error) {
      console.error("Error fetching slider details:", error);
    }
  };

  return (
    <section className="homeSlider">
      <div className="container-fluid">
        <Slider {...settings} className="homeSliderMain">
          {slider.map((image, index) => (
            <>
              <div className="item">
                <img
                  src={`${process.env.REACT_APP_API_URL}/${image.path}`}
                  className="w-100 imgsli"
                  style={{borderRadius:20}}
                />
                <h1 className=" display-2 text-overlay">{image.detail}</h1>
                
              </div>
            </>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Slide;
