import React from "react";
import './banners.css';
import {NavLink }from 'react-router-dom';
import { FaLongArrowAltRight } from "react-icons/fa";
import banner1 from '../../assets/img/banner-1.png'
import banner2 from '../../assets/img/banner-2.png'
import banner3 from '../../assets/img/banner-3.png'

const Banners = () => {
  return (
    <div className="bannerSection">
    <div className="container-fluid">
      <div className="row banner-main" > 
        <div className="col">
          <div className="box banner-img">
            <img src={banner1} className="w-100 transition" />
            <div className="banner-text">
              <h4>
                Rooted in Quality &amp; <br /> Growing Freshness
              </h4>
              <NavLink to={'/shop'} className="btn-bnr">
                Shop Now &nbsp; <FaLongArrowAltRight />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="box banner-img">
            <img src={banner2} className="w-100 transition" />
            <div className="banner-text">
              <h4>
              Sow Today &amp; <br /> Thrive Tomorrow
              </h4>
              <NavLink to={'/shop'} className="btn-bnr">
                Shop Now &nbsp; <FaLongArrowAltRight />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="box banner-img">
            <img src={banner3} className="w-100 transition" />
            <div className="banner-text">
              <h4>
              Where Every Seed  <br />  Has Its Own Story
              </h4>
              <NavLink to={'/shop'} className="btn-bnr">
                Shop Now &nbsp; <FaLongArrowAltRight />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Banners;
