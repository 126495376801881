import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/header/Header";
import Home from "./components/pages/home/index";
import About from "./components/pages/about/About";
import Contact from "./components/pages/contact/Contact";
import Blog from "./components/pages/blog/Blog";
import Shop from "./components/pages/shop/Shop";
import Footer from "./components/footer/Footer";
import Newsletter from "./components/newsletters/Newsletter";
import Featured from "./components/featured/Featured";
import NotFoundPage from "./components/pages/NotFound/NotFound";
import DetailsPage from "./components/pages/Details/Details";
import AddToCart from "./components/pages/cart/Cart";
import BlogPostDetails from "./components/pages/blog/BlogPostDetails";
import Login from "./components/user-components/login";
import SignUp from "./components/user-components/signUp";
import ForgetPassword from "./components/user-components/forget-password";
import ResetPassword from "./components/user-components/resetPassword";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route exact={true} path="/" element={<Home />} />
        <Route exact={true} path="/shop" element={<Shop />} />
        <Route exact={true} path="/blog" element={<Blog />} />
        <Route
          exact={true}
          path="/blog-post-details/:id"
          element={<BlogPostDetails />}
        />
        <Route exact={true} path="/about" element={<About />} />
        <Route exact={true} path="/contact" element={<Contact />} />
        <Route exact={true} path="/notFound" element={<NotFoundPage />} />
        <Route
          exact={true}
          path="/products/details"
          element={<DetailsPage />}
        />
        <Route
          exact={true}
          path="/products/AddToCart"
          element={<AddToCart />}
        />

        {/* <Route exact={true} path="/ju" element={<ProtectedForUser element={<Home />} />} /> */}
        <Route exact={true} path="/login" element={<Login />} />
        <Route exact={true} path="/signup" element={<SignUp />} />
        <Route
          exact={true}
          path="/forget-password"
          element={<ForgetPassword />}
        />
        <Route
          exact={true}
          path="/reset-password/:id"
          element={<ResetPassword />}
        />
      </Routes>
      <Newsletter />
      <Featured />
      <Footer />
    </>
  );
}

export default App;
