import React, { useEffect, useState } from "react";
import "./blog.css";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/blog/getBlog`
        );
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <section className="listingPage">
      <div className="container-fluid">
        <div className="breadcrumb1 flex-column">
          <h3>Farmer's Corner</h3>
          <ul className="list-inline-item mb-0">
            <li className="list-inline-item">
              <Link to="/">Home </Link>
            </li>
            <li className="list-inline-item">
              <ArrowForwardIcon /> Farmer's Corner
            </li>
          </ul>
        </div>
      </div>

      {/* blog section start here */}

      <div className="blog-main">
        <div className="container-fluid">
          <div className="row">
            {blogs.map((blog) => (
              <article key={blog._id} className="col-xl-3  text-center mb-30">
                <div className="blog-box">
                  <div className="post-thumb">
                    <Link to={`/blog-post-details/${blog._id}`}>
                      <img
                        className="blogImg"
                        src={`${process.env.REACT_APP_API_URL}/${blog.imageUrl}`}
                        alt=""
                      />
                    </Link>
                    <div className="entry-meta">
                      <FacebookShareButton url={`${process.env.REACT_APP_CLIENT_URL}/${blog._id}`} style={{ outline:'none'}} >
                        <FacebookIcon size={25} round />
                      </FacebookShareButton>
                      <WhatsappShareButton url={`${process.env.REACT_APP_CLIENT_URL}/${blog._id}`} style={{ outline:'none'}} >
                        <WhatsappIcon size={25} round />
                      </WhatsappShareButton>
                    </div>
                  </div>
                  <div className="entry-content-2">
                    <div className="d-flex list-align-item">
                      <div className="cat-left">
                        <h6>
                          <Link to="/blog-category">{blog.category}</Link>
                        </h6>
                      </div>
                      <div className="date-right">
                        <h6>
                          {new Date(blog.createdAt).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )}
                        </h6>
                      </div>
                    </div>

                    <h4 className="post-title mb-15">
                      <Link to={`/blog-post-details/${blog._id}`}>
                        {blog.title}
                      </Link>
                    </h4>

                    <div className="entry-meta ">
                      <div className="blog-desc">
                        <p
                          className="blog-desc-para"
                          dangerouslySetInnerHTML={{
                            __html: blog.content.substring(0, 150) + '...',
                          }}
                        ></p>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
      {/* blog section end here */}
    </section>
  );
};

export default Blog;
