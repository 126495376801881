import React, { useState } from "react";
import axios from "axios";
import './users.css'; 
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const [mail, setMail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/password/forget-password`, { mail });
      toast.success(res.data.message);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="container">
      <h1 className="h1">Forget Password Here</h1>
      <form onSubmit={handleSubmit} className="form">
        <input
          type="email"
          placeholder="Email Id"
          id="mailId"
          required
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          className="input"
        />
        <button type="submit" id="btn" className="button">
          Submit
        </button>
        <br />
      </form>
      <a href="/signup" className="link">
        Don't have an account? Click here to SignUp
      </a>
      <br />
      <a href="/login" className="link">
        Click here to login
      </a>
    </div>
  );
};

export default ForgetPassword;
