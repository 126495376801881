import React from "react";
import "./topProducts.css";
import { Link } from "react-router-dom";
import Thumb1 from "../../assets/img/thumbnail-1.jpg";
import Thumb2 from "../../assets/img/thumbnail-2.jpg";
import Thumb3 from "../../assets/img/thumbnail-3.jpg";
import Rating from "@mui/material/Rating";

const TopProducts = (props) => {
  return (
    <>
      <div className="topSellingBox">
        <div className="heading">
          <h3>{props.title}</h3>
        </div>
        <div className="items d-flex align-items-center">
          <div className="img">
            <Link to="">
              <img src={Thumb1} className="w-100" />
            </Link>
          </div>

          <div className="info px-3">
            <Link to="">
              <p>Black Mustured</p>
            </Link>
            <Rating
              name="half-rating-read"
              defaultValue={3.5}
              precision={0.5}
              readOnly
            />
            <div className="d-flex align-items-center">
              <span className="price font-weight-bold">Rs.28.85</span>
              <span className="oldPrice">Rs.28.85</span>
            </div>
          </div>
        </div>
        <div className="items d-flex align-items-center">
          <div className="img">
            <Link to="">
              <img src={Thumb1} className="w-100" />
            </Link>
          </div>

          <div className="info px-3">
            <Link to="">
              <p>Black Mustured</p>
            </Link>
            <Rating
              name="half-rating-read"
              defaultValue={3.5}
              precision={0.5}
              readOnly
            />
            <div className="d-flex align-items-center">
              <span className="price font-weight-bold">Rs.28.85</span>
              <span className="oldPrice">Rs.28.85</span>
            </div>
          </div>
        </div>
        <div className="items d-flex align-items-center">
          <div className="img">
            <Link to="">
              <img src={Thumb1} className="w-100 proTopImg" />
            </Link>
          </div>

          <div className="info px-3">
            <Link to="">
              <p>Black Mustured</p>
            </Link>
            <Rating
              name="half-rating-read"
              defaultValue={3.5}
              precision={0.5}
              readOnly
            />
            <div className="d-flex align-items-center">
              <span className="price font-weight-bold">Rs.28.85</span>
              <span className="oldPrice">Rs.28.85</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopProducts;
