import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
import './users.css'; 

const SignUp = () => {
  const [userName, setUserName] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== cnfPassword) {
      setErrorMessage("Passwords didn't match");
      setPassword("");
      setCnfPassword("");
    } else {
      setErrorMessage("");
      const user = { name: userName, mail, password };
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/signUp`, user);
        toast.success(res.data.message);
        navigate('/login'); // Navigate to the login page
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div className="container">
      <h1 className="h1">SignUp Here</h1>
      <form onSubmit={handleSubmit} className="form">
    
        <input
          type="text"
          placeholder="Username"
          id="userName"
          required
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          className="input"
        />
       
        <input
          type="email"
          placeholder="email id"
          id="mailId"
          required
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          className="input"
        />
       
        <input
          type="password"
          placeholder="Password"
          id="psw"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input"
        />
        <input
          type="password"
          placeholder="Confirm Password"
          id="cnf-psw"
          required
          value={cnfPassword}
          onChange={(e) => setCnfPassword(e.target.value)}
          className="input"
        />
        {errorMessage && <p className="error">{errorMessage}</p>}
        <button type="submit" id="btn" className="button">
          Sign up
        </button>
      </form>
      <a href="/login" className="link">
        Already a user? Click here to login
      </a>
    </div>
  );
};

export default SignUp;
