import React from 'react';
import './newsletter.css';
import Banner9 from "../../assets/img/banner-9.png";

const Newsletter = () => {
  return (
    <>
    <section className="newsletter mb-15">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="position-relative newsletter-inner">
                <div className="newsletter-content">
                  <h2 className="mb-20">
                  Providing online platform <br />for ordering seeds, 
                  tracking performance, <br />and receiving recommendations
                  </h2>
                  
                  <form className="form-subcriber d-flex">
                    <input type="email" placeholder="Your emaill address" />
                    <button className="btn" type="submit">
                      Subscribe
                    </button>
                  </form>
                </div>
                <img src={Banner9} alt="newsletter" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Newsletter