import React from "react";
import "./nav.css";
import Button from "@mui/material/Button";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Link } from "react-router-dom";

const Nav = ({ isNavOpen, setIsNavOpen }) => {
  const handleCloseNav = () => {
    if (setIsNavOpen) {
      setIsNavOpen(false);
    }
  };

  return (
    <>
      <nav className={`navMenu ${isNavOpen ? "open" : ""}`}>
        <div className="nav d-flex align-items-center ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-8">
                <nav>
                  <ul className="list list-inline mb-0">
                    <li className="list-inline-item">
                      <Button onClick={handleCloseNav}>
                        <Link to="/">Home</Link>
                      </Button>
                    </li>
                    <li className="list-inline-item">
                      <Button onClick={handleCloseNav}>
                        <Link to="/about">About Us</Link>
                      </Button>
                    </li>
                    <li className="list-inline-item">
                      <Button onClick={handleCloseNav}>
                        <Link to="/shop">Shop</Link>
                      </Button>
                    </li>
                    <li className="list-inline-item">
                      <Button onClick={handleCloseNav}>
                        <Link to="/blog">Farmer's Corner</Link>
                      </Button>
                    </li>
                    <li className="list-inline-item">
                      <Button onClick={handleCloseNav}>
                        <Link to="/contact">Contact Us</Link>
                      </Button>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-sm-4 d-flex align-items-center part3">
                <div className="phNo d-flex align-items-center">
                  
                  <div className="info ml-3">
                    
                    <h5 className="mb-0"><SupportAgentIcon /> +91-7370050737</h5>
                    <p className="mb-0" style={{marginLeft: 25}}>24/7 HelpLine No.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
