import React from "react";
import "./featured.css";
import icon1 from "../../assets/img/icon-1.svg";
import icon2 from "../../assets/img/icon-2.svg";
import icon3 from "../../assets/img/icon-3.svg";
import icon4 from "../../assets/img/icon-4.svg";

const Featured = () => {
  return (
    <>
      <div className="featuredSection">
        <div className="featuredBoxes">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="box d-flex align-items-center w-100">
                  <span>
                    <img src={icon1} alt="" />
                  </span>
                  <div className="info">
                    <h3>Germination Rates</h3>
                   
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="box d-flex align-items-center w-100">
                  <span>
                    <img src={icon3} alt="" />
                  </span>
                  <div className="info">
                    <h3>Certified Seeds</h3>
                    
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="box d-flex align-items-center w-100">
                  <span>
                    <img src={icon4} alt="" />
                  </span>
                  <div className="info">
                    <h3>Consistent Supply</h3>
                    
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="box d-flex align-items-center w-100">
                  <span>
                    <img src={icon2} alt="" />
                  </span>
                  <div className="info">
                    <h3>Adaptability</h3>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Featured;
