import React from "react";
import "./sidebar.css";
import category1 from "../../assets/img/category-1.svg";
import Slider from "@mui/material/Slider";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import Banner11 from "../../assets/img/banner-11.png";

function valuetext(value) {
  return `${value}°C`;
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const SideBar = () => {
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="sidebar">
        <div className="card border-0 shadow">
          <h3>Category</h3>

          <div className="catList">
            <div className="catItem d-flex align-items-center">
              <span className="img">
                <img src={category1} width={30}></img>
              </span>
              <h4 className="mb-0 ml-3 mr-3 "> Grain Seeds</h4>
              <span className="d-flex align-items-center rounded-circle ml-auto justify-content-center">
                30
              </span>
            </div>
          </div>

          <div className="catList">
            <div className="catItem d-flex align-items-center">
              <span className="img">
                <img src={category1} width={30}></img>
              </span>
              <h4 className="mb-0 ml-3 mr-3"> Grain Seeds</h4>
              <span className="d-flex align-items-center rounded-circle ml-auto justify-content-center">
                30
              </span>
            </div>
          </div>

          <div className="catList">
            <div className="catItem d-flex align-items-center">
              <span className="img">
                <img src={category1} width={30}></img>
              </span>
              <h4 className="mb-0 ml-3 mr-3"> Grain Seeds</h4>
              <span className="d-flex align-items-center rounded-circle ml-auto justify-content-center">
                30
              </span>
            </div>
          </div>

          <div className="catList">
            <div className="catItem d-flex align-items-center">
              <span className="img">
                <img src={category1} width={30}></img>
              </span>
              <h4 className="mb-0 ml-3 mr-3"> Grain Seeds</h4>
              <span className="d-flex align-items-center rounded-circle ml-auto justify-content-center">
                30
              </span>
            </div>
          </div>
        </div>

        <div className="card border-0 shadow">
          <h3>Filter By Price</h3>
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            color="success"
            min={0}
            max={1000}
          />
          <div className="d-flex pt-2 pb-2 princeRange">
            <span>
              Form: <strong className="text-success">Rs.{value[0]}</strong>
            </span>
            <span className="ml-auto">
              To: <strong className="text-success">Rs.{value[1]}</strong>
            </span>
          </div>

          <div className="filters">
            <h5> Color</h5>
            <ul>
              <li>
                <Checkbox {...label} color="success" /> Red (56)
              </li>
              <li>
                <Checkbox {...label} /> Red (56)
              </li>
              <li>
                <Checkbox {...label} /> Red (56)
              </li>
              <li>
                <Checkbox {...label} /> Red (56)
              </li>
              <li>
                <Checkbox {...label} /> Red (56)
              </li>
              <li>
                <Checkbox {...label} /> Red (56)
              </li>
              <li>
                <Checkbox {...label} /> Red (56)
              </li>
              <li>
                <Checkbox {...label} /> Red (56)
              </li>
              <li>
                <Checkbox {...label} /> Red (56)
              </li>
              <li>
                <Checkbox {...label} /> Red (56)
              </li>
              <li>
                <Checkbox {...label} /> Red (56)
              </li>
              <li>
                <Checkbox {...label} /> Red (56)
              </li>
              <li>
                <Checkbox {...label} /> Red (56)
              </li>
            </ul>
          </div>
          <Button
            className="btn-filter"
            style={{ background: "green", color: "white" }}
          >
            Filter
          </Button>
        </div>

        <img src={Banner11} className="w-100 sideimg"></img>
      </div>
    </>
  );
};

export default SideBar;
