import React, { useRef, useState } from "react";
import "./details.css";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import product from "../../../assets/img/seeds-1.webp";
import Slider from "react-slick";
import Rating from "@mui/material/Rating";
import { Button } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SideBar from "../../sideBar/SideBar";
import QuantityBox from "../../quantityBox/QuantityBox";

const Details = () => {
  const [zoomImage, setZoomImage] = useState();

  const [activeTabs, setActiveTabs] = useState(0);

  const zoomSlider = useRef();

  var settings = {
    dots: false, //dots ko slider se remove krne ke liye
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    fade: false,
    arrows: true, //arrow ko slider me add krne ke liye over image
    autoplay: 500,
    centerMode: true,
  };

  const goto = (index) => {
    alert(index);
  };

  return (
    <section className="detailsPage" ref={zoomSlider}>
      <div className="container-fluid">
        <div className="breadcrumb1 flex-column">
          <h3>Shop</h3>
          <ul className="list-inline-item mb-0">
            <li className="list-inline-item">
              <Link to="/">Home </Link>
            </li>
            <li className="list-inline-item">
              <ArrowForwardIcon /> <Link to={"/shop"}>Shop</Link>
            </li>
            <li className="list-inline-item">
              <ArrowForwardIcon /> Product Detail
            </li>
          </ul>
        </div>
      </div>

      <div className="container-fluid detailsContainer pt-3 pd-3">
        <div className="row">
          <div className="col-md-9 part1">
            <div className="row">
              {/* Product Zoom code Start here */}
              <div className="col-md-5">
                <div className="productZoom">
                  <InnerImageZoom
                    zoomType={"hover"}
                    zoomScale={1.5}
                    src={product}
                  />
                </div>

                <Slider {...settings} className="zoomSlider">
                  <div className="item">
                    <img
                      src={product}
                      className="w-100"
                      onClick={() => goto(0)}
                    />
                  </div>
                  <div className="item">
                    <img
                      src={product}
                      className="w-100"
                      onClick={() => goto(0)}
                    />
                  </div>
                  <div className="item">
                    <img
                      src={product}
                      className="w-100"
                      onClick={() => goto(0)}
                    />
                  </div>
                  <div className="item">
                    <img
                      src={product}
                      className="w-100"
                      onClick={() => goto(0)}
                    />
                  </div>
                  <div className="item">
                    <img
                      src={product}
                      className="w-100"
                      onClick={() => goto(0)}
                    />
                  </div>
                  <div className="item">
                    <img
                      src={product}
                      className="w-100"
                      onClick={() => goto(0)}
                    />
                  </div>
                  <div className="item">
                    <img
                      src={product}
                      className="w-100"
                      onClick={() => goto(0)}
                    />
                  </div>
                </Slider>
              </div>
              {/* Product Zoom code End here */}

              {/* Product Information code Start here */}
              <div className="col-md-7 productInfo">
                <h1>Vegitable Seeds</h1>
                <div className="d-flex align-items-center mb-4">
                  <Rating
                    name="half-rating-read"
                    defaultValue={3.5}
                    precision={0.5}
                    readOnly
                  />
                  <span>(32 Review)</span>
                </div>

                <div className="priceSec d-flex align-items-center mb-4">
                  <span className="priceLarge">Rs. 38</span>
                  <div className="ml-2 d-flex flex-column">
                    <span className="pricePerOff">26% off</span>
                    <span className="priceSmall">Rs. 56</span>
                  </div>
                </div>

                <div className="short-desc">
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Aliquam rem officia, corrupti reiciendis minima nisi modi,
                    quasi, odio minus dolore impedit fuga eum eligendi.
                  </p>
                </div>

                <div className="productSize d-flex align-items-center ">
                  <span>Size / Weight: </span>
                  <ul className="list list-style mb-0 pl-4">
                    <li className="list-inline-item">
                      <a href="#" className="tag">
                        100g
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <Link to={"#"} className="tag">
                        250g
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to={"#"} className="tag">
                        500g
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to={"#"} className="tag">
                        1kg
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to={"#"} className="tag">
                        5kg
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="addCartSection pt-4 pb-4 d-flex align-items-center">
                  <div className="mr-2">
                    <QuantityBox />
                  </div>

                  <Button className="btnAddToCart ml-3">
                    {" "}
                    <AddShoppingCartIcon />
                    &nbsp; Add to Cart{" "}
                  </Button>
                  <Button className="btnWishlist ml-3">
                    {" "}
                    <FavoriteBorderIcon />
                    &nbsp;
                  </Button>
                </div>
              </div>
              {/* Product Information code End here */}
            </div>
          </div>
          {/* <div className="col-md-3 part2 sidebarWrapper">
            <SideBar />
          </div> */}
        </div>

        <div className="card mt-5 p-5 detailsPageTabs">
          <div className="customTabs">
            <ul className="list list-inline">
              <li className="list-inline-item">
                <Button onClick={() => setActiveTabs(0)}> Description </Button>
              </li>
              <li className="list-inline-item">
                <Button onClick={() => setActiveTabs(1)}> Review </Button>
              </li>
            </ul>

            {activeTabs === 0 && (
              <div className="tabContent">
                <p>
                  Scientifically referred to as Brassica nigra, black mustard
                  seeds are an important component of many cuisines across the
                  world, especially those of South Asia, the Mediterranean, and
                  Africa. These tiny, spherical seeds are a mainstay in pickling
                  and spice mixtures due to their strong taste.
                </p>
                <p>
                  Black mustard seeds are native to the Mediterranean region and
                  have been grown for thousands of years for both culinary and
                  medicinal uses. They are an essential component in Indian
                  cooking, where their nutty and peppery taste is released by
                  tempering them in heated oil, giving curries, dals, and
                  vegetable dishes more depth.
                </p>
                <p>
                  Black mustard seeds are used in cooking and have historical
                  value as well. Their modest stature and capacity to develop
                  into giant plants have led to references to them in both
                  ancient Sanskrit scriptures and the Bible, where they
                  represent faith and the Kingdom of Heaven.
                </p>
                <p>
                  From a botanical perspective, black mustard seeds originate
                  from the mustard plant, which is a member of the Brassicaceae
                  family, which also includes Brussels sprouts, cabbage, and
                  broccoli. The seeds themselves have a diameter of around 1 mm
                  and come in a range of colors, from dark brown to black. They
                  emit an aromatic oil when crushed or pulverized, which gives
                  them a unique taste character.
                </p>
                <p>
                  Black mustard seeds are commonly used in Indian cookery to
                  make tadka or tempering. This method is heating oil or ghee
                  till hot, then adding mustard seeds and other spices like as
                  cumin and fenugreek, and fast frying them until they pop and
                  release their fragrances into the oil. The infused oil is then
                  poured over the foods to improve their flavor.
                </p>
                <p>
                  Beyond their culinary applications, black mustard seeds have
                  health advantages. They are high in essential oils, vitamins,
                  and minerals including calcium, magnesium, and selenium.
                  Traditionally, they were employed in herbal medicine to
                  increase appetite, improve digestion, and treat respiratory
                  congestion.
                </p>

                <hr />

                <ul>
                  <li>It flourished at 10 to 25 degree Celsius.</li>
                  <li>It takes 3 to 5 days to germinate.</li>
                  <li>
                    They thrive in soils with pH ranging from 6.0 to 7.5 PH.
                  </li>
                </ul>
              </div>
            )}
            {activeTabs === 1 && (
              <div className="tabContent">
                <div className="row">
                  <div className="col-md-8">
                    <h3>Customer questions & answers</h3>
                    <br />
                    <div className="card p-3"></div>
                  </div>

                  <div className="col-md-4"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
